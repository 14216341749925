import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import axios from "axios";

export function initSentry() {
  if (
    !process.env.REACT_APP_SENTRY_DSN ||
    !process.env.REACT_APP_SENTRY_RELEASE ||
    !process.env.REACT_APP_SENTRY_ENVIRONMENT
  ) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    autoSessionTracking: true,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "local",
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      "Network Error",
      "timeout of 0ms exceeded",
      "Request aborted",
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,

    beforeSend: function (event, hint) {
      const exception = hint?.originalException;

      if (exception && axios.isAxiosError(exception)) {
        const status = exception.response?.status;

        const cleanedMessage:
          | string
          | undefined = exception.response?.data.message.replace(
          /(Something went wrong, but we logged the issue with request ID: )([A-z0-9]+)(.*)/,
          "$1<REQUEST_ID>$3"
        );

        event.fingerprint = [
          "{{ default }}",
          exception.config.method || "",
          status ? status + "" : "",
        ];

        if (!event.contexts) {
          event.contexts = {};
        }

        if (cleanedMessage) {
          event.message = cleanedMessage;
        }

        event.contexts["axios"] = {
          method: exception.config.method || "",
          url: exception.config.url || "",
          status: status ? status + "" : "",
          message: cleanedMessage || "",
        };
      }

      return event;
    },
  });
}
