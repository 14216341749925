import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useBodyClass } from "@/utils/style";

export function App() {
  useBodyClass("bg-gray-50 text-gray-800 text-base antialiased");

  return (
    <HelmetProvider>
      <Router basename="/restaurant">
        <Switch>
          <Route path="/:slug">
            <Restaurant />
          </Route>
        </Switch>
      </Router>
    </HelmetProvider>
  );
}

interface MenuParams {
  slug: string;
}

export function Restaurant() {
  const { slug } = useParams<MenuParams>();

  window.location.href = `https://ordersave.com/${slug}`;

  return <div>Redirecting...</div>;
}
